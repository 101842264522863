import "./style.scss";
import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { mailUnread } from "ionicons/icons";
import { FC } from "react";

interface EmailWaitingProps {
  email?: string;
  opacity?: number;
}

const EmailWaiting: FC<EmailWaitingProps> = ({ email, opacity }) => {
  const basePath = process.env.REACT_APP_BASE_PATH ?? "/";

  return (
    <IonPage>
      <IonContent class="login-content ion-padding ion-text-center">
        <IonHeader no-border class="verify-sent-header">
          <IonToolbar color="transparent">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/welcome/login" mode="ios" text="Log in" color="light" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonRow style={{ height: "100%", padding: "5%" }} class="ion-align-items-center">
          <IonCol style={{ height: "100%" }} align-self-center>
            <div className="verify-sent-container">
              <div className="login-top-container verify-sent-top-container" style={{ opacity }}>
                <h1>Great rates.</h1>
                <h1>Instant payouts.</h1>
                <h1>Lots of shifts.</h1>
                <IonImg src={`${basePath}assets/logo/cbh-logo.png`} className="logo" />
              </div>
              <div>
                <IonIcon size="large" style={{ color: "white" }} icon={mailUnread} mode="md" />
                <p>We sent a login link to {email || "your email address"}.</p>
                <p>Check your inbox to finish logging in.</p>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export { EmailWaiting };
